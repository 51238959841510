import React from "react"

export default function ProjectAttributeItem(props) {
  return (
    <div className="attributeItem">
      <h4 className="attributeTitle">{props.title}</h4>
      <p className="attributeDetail">{props.detail}</p>
    </div>
  )
}


