import * as React from "react"
import Seo from "../../components/seo"
import "../../styles/styles.sass"
import Header from "../../components/header"
import Footer from "../../components/footer"
import { StaticImage } from "gatsby-plugin-image"
import ProjectAttribute from "../../views/project/projectAttribute"
import ProjectDetail from "../../views/project/projectDetail"
import ProjectSolution from "../../views/project/projectSolution"
import HireBanner from "../../views/hire/hireBanner"

const CueHitPage = () => {
  return (
  <main className="mercuryView projectView projectCueHit">
    <Seo
      title="Phil Amour — CueHit"
      description="Read my case study on CueHit to learn about my role and approach to product design."
      image="/imageSEOCueHit.jpg"
    />
    
    <Header headerType="detail" />

    <div className="mercuryContent">
      <ProjectDetail
        projectIcon={
          <StaticImage
            src="../../images/imageProjectPreviewIconCueHit.png"
            placeholder="blurred"
            layout="constrained"
            quality={100}
            alt="A Snupps app icon."
          />
        }
        projectName="CueHit"
        projectOwner="CueHit, Inc."
        projectButtonLink="https://www.cuehit.com/"
        projectButtonTitle="See it Live"
        projectButtonType="dual"
        projectSecondButtonLink="https://blog.neogov.com/press/2022-agency360-cuehit-planit"
        projectSecondButtonTitle="Acquired"
        projectDescription="A complete Community Engagement solution for 9-1-1, Law Enforcement and Fire/EMS."
        projectSolutions={<div>
          <ProjectAttribute
            title="SMS Surveys"
            detail="Automatic text surveys sent a few hours or a few days after 9-1-1 calls to measure satisfaction of callers for non-critical incidents."
          />

          <ProjectAttribute
            title="Feedback Board"
            detail="Improve staff morale with positive citizen feedback. Dynamic positive feedback board for display throughout the department."
          />

          <ProjectAttribute
            title="Data Analytics and Dashboards"
            detail="Analyze response rates to surveys over time and by type of incident to measure engagement. Many 9-1-1 centers are seeing greater than 50% response rates!"
          />

          <ProjectAttribute
            title="Follow-Up Tasks"
            detail="Automatically create follow up tasks for less than satisfactory survey scores or negative officer feedback."
          />

          <ProjectAttribute
            title="Automatic Email Digests"
            detail="Automatic email delivery of personal satisfaction score to officers with summary of positive comments received from citizens"
          />

          <ProjectAttribute
            title="Low-Acuity Services"
            detail="Coordinate alternative response or co-responders for non-emergent & low acuity situations. Call in crisis assistance when police aren't needed, or work with local providers to schedule patients for non-emergent care."
          />
        </div>}
        projectRole="Cofounder & Head of Design"
        projectDuration="2019-2021"
        projectSummary="Designed and built the front-end of a SaaS targeted at 911 call centers, law enforcement, EMS, and fire agencies that reshapes citizen connections for public safety. Partnered with Union County PD and 9-1-1 in New Jersey to introduce a new era of community engagement for public safety. Thanks to our innovative solution, the NJ department is seeing a 40% response rate for post-incident surveys vs less than five percent before. Designed and built the front-end of many innovative features - surveys, automated rules, communication tools (text/email/video calls/chatbots), analytics, internal mobile app, etc. After partnering with 50+ paying customers ranging from police departments to 911 call centers and incurring 1M+ in ARR, CueHit has been acquired by NEOGOV."
        projectAttributes={<div>
          <ProjectAttribute
            title="Design"
            detail="End-to-End Product Design, UI Design, User Research, Interaction Design, High-Fidelity Prototyping, Usability Testing, Figma"
          />

          <ProjectAttribute
            title="Front-End Development"
            detail="React.js, Gatsby.js, CSS/SASS, UI Architecture"
          />

          <ProjectAttribute
            title="Platforms"
            detail="Web (Desktop), Mobile (Cross-Platform)"
          />

          <ProjectAttribute
            title="Business"
            detail="Public Safety, SaaS, B2B"
          />
        </div>}
      />

      <div className="solutionsList">
        <ProjectSolution
          solutionLayout="bottom"
          solutionTitle="SMS Surveys"
          solutionDetail="Automatic text surveys sent a few hours or a few days after 9-1-1 calls to measure satisfaction of callers for non-critical incidents."
          solutionImage={
            <StaticImage
              src="../../images/imageProjectDetailCueHitSolution1.png"
              placeholder="blurred"
              layout="fullWidth"
              quality={100}
              alt="A CueHit mockup."
            />
          }
        />

        <ProjectSolution
          solutionLayout="bottom"
          solutionTitle="Feedback Board"
          solutionDetail="Improve staff morale with positive citizen feedback. Dynamic positive feedback board for display throughout the department."
          solutionImage={
            <StaticImage
              src="../../images/imageProjectDetailCueHitSolution2.png"
              placeholder="blurred"
              layout="fullWidth"
              quality={100}
              alt="A CueHit mockup."
            />
          }
        />

        <ProjectSolution
          solutionLayout="bottom"
          solutionTitle="Data Analytics and Dashboards"
          solutionDetail="Analyze response rates to surveys over time and by type of incident to measure engagement. Many 9-1-1 centers are seeing greater than 50% response rates!"
          solutionImage={
            <StaticImage
              src="../../images/imageProjectDetailCueHitSolution3.png"
              placeholder="blurred"
              layout="fullWidth"
              quality={100}
              alt="A CueHit mockup."
            />
          }
        />

        <ProjectSolution
          solutionLayout="left"
          solutionTitle="Follow-Up Tasks"
          solutionDetail="Automatically create follow up tasks for less than satisfactory survey scores or negative officer feedback."
          solutionImage={
            <StaticImage
              src="../../images/imageProjectDetailCueHitSolution4.png"
              placeholder="blurred"
              layout="fullWidth"
              quality={100}
              alt="A CueHit mockup."
            />
          }
        />

        <ProjectSolution
          solutionLayout="bottom"
          solutionTitle="Automatic Email Digests"
          solutionDetail="Automatic email delivery of personal satisfaction score to officers with summary of positive comments received from citizens"
          solutionImage={
            <StaticImage
              src="../../images/imageProjectDetailCueHitSolution5.png"
              placeholder="blurred"
              layout="fullWidth"
              quality={100}
              alt="A CueHit mockup."
            />
          }
        />

        <ProjectSolution
          solutionLayout="right"
          solutionTitle="Low-Acuity Services"
          solutionDetail="Coordinate alternative response or co-responders for non-emergent & low acuity situations. Call in crisis assistance when police aren't needed, or work with local providers to schedule patients for non-emergent care."
          solutionImage={
            <StaticImage
              src="../../images/imageProjectDetailCueHitSolution6.png"
              placeholder="blurred"
              layout="fullWidth"
              quality={100}
              alt="A CueHit mockup."
            />
          }
        />

        <HireBanner
          bannerTitle=""
          bannerSubtitle=""
        />

      </div>
    </div>
    
    <Footer />
  </main>
  )
}

export default CueHitPage
